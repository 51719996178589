export default () => ({
  paper: {
    color: '#81d5ad'
  },
  button: {
    backgroundColor: '#81d5ad'
  },
  content: {
    fontSize: 36,
    lineHeight: '35px'
  }
});
